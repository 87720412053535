import React from 'react'
import { graphql } from 'gatsby'
import classNames from 'classnames'
import { SinglePage } from '../templates/SinglePage'
import { SocialSharing } from '../components/shared'
import { Seo } from '../components/base'
import { getPageBuilderComponents } from '../components/base/PageBuilderComponents'
import { getLocaleMarket } from '../utils/getLocaleMarket'
import * as styles from '../../src/css/atom/bioNewsSingle.module.scss'
import { getGermanDateFromSanityDate } from '../utils/getGermanDateFromSanityDate'

const NewsSingle = ({ data, location, pageContext }) => {
  const {
    title,
    seo,
    category,
    basePath,
    subtitle = null,
    externalUrl,
    tags,
    image,
    imageMobile,
    slug,
    previewImage,
    pageBuilder,
    pageId,
    newsMetaInfo,
    fullWidthHeader = false
  } = data.sanityNews
  const date = getGermanDateFromSanityDate(newsMetaInfo?.releaseDate)
  const languages = { pageContext }
  let myMarket = getLocaleMarket()

  return (
    <SinglePage
      heroImage={image}
      heroImageMobile={imageMobile}
      noHeroTitle
      translations={languages}
      fullWidthHeader={fullWidthHeader}>
      <Seo
        title={`${
          category[0].parent?.title ? category[0].parent?.title + ' -> ' : ''
        } ${category[0].title}: ${seo?.metaTitle}`}
        description={seo?.metaDescription}
        image={seo?.ogImage?.asset?.url}
      />
      <section className="md:mx-auto singlePageSection">
        <div className="mb-20 mt-10">
          <h1 className="text-center" data-cy="post-title">
            {title}
          </h1>
          {subtitle && (
            <p
              className="md:mt-3 text-lg md:text-xl text-center uppercase"
              style={{ lineHeight: '1.1666' }}>
              {subtitle}
            </p>
          )}
          <div
            className={classNames(
              styles.newsSingle,
              'md:mt-3 text-lg md:text-xl text-center uppercase'
            )}>
            <span>{newsMetaInfo.location}</span>
            &nbsp;|&nbsp;
            <span>{date}</span>
            &nbsp;|&nbsp;
            <span>
              {newsMetaInfo.author[0]?.firstName}&nbsp;
              {newsMetaInfo.author[0]?.lastName}
            </span>
          </div>
          {pageBuilder.map((item, index) => (
            <React.Fragment key={index}>
              {getPageBuilderComponents(
                { ...item, pageId: pageId },
                myMarket,
                pageContext
              )}
            </React.Fragment>
          ))}
        </div>
        <SocialSharing link={location} />
      </section>
    </SinglePage>
  )
}

export default NewsSingle

export const query = graphql`
  query ($id: String) {
    sanityNews(_id: { eq: $id }) {
      title
      subtitle
      seo {
        ...SeoQuery
      }
      newsMetaInfo {
        author {
          firstName
          lastName
        }
        location
        releaseDate
      }
      pageId: _id
      category {
        ... on SanityCategory {
          id
          title
          slug
          parent: parentCategory {
            title
            slug
          }
        }
      }
      externalUrl
      tags {
        ... on SanityNewsTag {
          title
          slug
        }
      }
      slug: slug {
        current
      }
      image {
        ...ImageObjectQuery
      }
      imageMobile {
        ...ImageQuery
      }
      previewImage {
        ...ImageObjectQuery
      }
      ...NewsPageBuilderElements
    }
  }
`
